import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

import BlogCard from './BlogCard/BlogCard';
import PaginationCard from './PaginationCard/PaginationCard';
import SearchCard from './SearchCard/SearchCard';
import {CommonService} from '../../../services/common-services';

const Blogs = ({ isLoggedIn }) => {

  // blogs data
  const [blogsData, setBlogsData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [labelsOptions, setLabelsOptions] = useState([]);

  // blogs labels search section
  const selectOptions = (array) => {
    return array.map(option => ({ value: option, label: option }));
  };

  const handleLabelsChange = (selectedOptions) => {
    setLabels(selectedOptions);
  };

  // pagination card
  const [currentPage, setCurrentPage] = useState(1);
  const [totalBlogsSize, setTotalBlogsSize] = useState(0);
  const blogsPerPage = 20;
  
  // semantic search card
  const [currentSearchPage, setCurrentSearchPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("")
  const [useSemanticSearch, setUseSemanticSearch] = useState(false);
  
  const getAllBlogs = async (labels, currentPage) => {
    const blogs = await CommonService.getAllBlogs({
      'page_limit': blogsPerPage,
      'page_number': currentPage,
      'labels': labels.map(
        label => label.value?.split('-')[0].trim()
      ).join(','),
    });
    setBlogsData(blogs.allBlogs);
    setTotalBlogsSize(blogs.allCount);
  };

  const getBlogsLabels = async () => {
    const blogsLabels = await CommonService.getAllBlogsLabel()
    setLabelsOptions(blogsLabels?.allBlogsLabels?.map(blogLabel => blogLabel.blog_label))
  };

  useEffect(() => {
    getBlogsLabels();
  }, [])

  useEffect(() => {
    getAllBlogs(labels, currentPage);
  }, [labels, currentPage]);
   
 return (
    <>

    <div className="flex-row justify-center align-center gap-1 flex-wrap">
      <div className='text-center'>
        <div className='multi-select'>
            <Select
              isMulti
              name="labels"
              options={selectOptions(labelsOptions)}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Filter blogs with labels ..."
              onChange={handleLabelsChange}
              value={labels}
            />
        </div>
      </div>
      <div>
      <div className='button button-style3' onClick={() => setUseSemanticSearch(!useSemanticSearch)}>Try AI Search</div>
      </div>
    </div>
    { !useSemanticSearch && (
      <PaginationCard
      currentPage={currentPage}
      totalPages={Math.ceil(totalBlogsSize/blogsPerPage)}
      setCurrentPage={setCurrentPage}
      />
    )}
    { useSemanticSearch && (
      <SearchCard
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      currentSearchPage={currentSearchPage}
      setCurrentSearchPage={setCurrentSearchPage}
      blogsPerPage={blogsPerPage}
      setBlogsData={setBlogsData}
      />
    )}
    <div className="blog-list-container">
      <div className="blog-list">
        {blogsData.map((blog, index) => (
          <BlogCard key={index} {...blog} />
        ))}
      </div>
    </div>

    </>
 )
}

export default  Blogs;